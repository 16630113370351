import React from "react";
import Section from "../../components/Section/Section";
import "./Details.scss";

const Details = ({ title, items }) => {
  return (
    <Section className="Details">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <h3 className="title">{title}</h3>
          </div>
          <div className="col-lg-8">
            <div className="row">
              {items &&
                items.map((item, i) => {
                  return (
                    <div className="col-lg-4 col-md-4 col-sm-6" key={i}>
                      <h5 className="itemTitle">{item.title}</h5>
                      <div className="separator" />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Details;
